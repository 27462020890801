.Type {
  margin-left: 0.5rem;
}

.GeomParam {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.Alert {
  width: 100%;
}

.Geom {
  flex: 1;
  display: flex;
  align-items: center;
}

.Icon {
  margin-right: 0.5rem;
}

.FeatureList {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Feature {
  margin: 0.25rem 0;
  display: flex;
  justify-content: space-between;
}
.FeatureActions {
  display: flex;
  align-items: center;
}
.FeatureActions button {
  margin-left: .5rem;
}