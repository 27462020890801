.CollectionParam {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.CollectionList {
  display: flex;
  flex-direction: column;
}

.CollectionItem {
  display: flex;
  flex-direction: row;
  margin: .25rem 0;
}

.CollectionInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.CollectionActions {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.CollectionTitle {
  font-weight: bold;
}
.CollectionDescription {
  font-size: smaller;
  text-overflow: ellipsis;
}

.SubText {
  font-size: x-small;
  color: grey;
  text-overflow: ellipsis;
  overflow: inherit;
}