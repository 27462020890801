.ParamList {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}

.Param {
  width: 100%;
  margin: .5rem 0;
  display: flex;
  flex-direction: column;
}
.ParamTitle {
  font-variant: all-small-caps;
  font-weight: bold;
  margin-bottom: .5rem;
}