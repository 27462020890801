.LogContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.LogList {
    overflow: auto;
    align-items: center;
    margin-top: 1.5rem;
    box-shadow: inset 0 20px 15px -20px rgba(0,0,0, 0.1);
    padding-top: 0.5rem;
}
.Log {
    display: flex;
    flex-direction: column;
    margin: .5rem 0;
    width: 100%;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 0.5rem;
}
.Header {
    display: flex;
    width: 100%;
    justify-content: center;
}
.Message {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: .25rem;
}
.Message div {
    overflow-wrap: anywhere;
}
.Date {
    font-size: small;
    font-weight: bold;
    flex: 1;
    display: flex;
    align-items: center;
}
.Status {
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    width: 100px;
    padding: .25rem;
    color: white;
    font-variant: all-small-caps;
    text-align: center;
    border-radius: 20px;
    font-size: small;
}

.error {
    background-color:  #dc3545;
}
.error_inactive {
    color:  #dc3545;
    border: 1px solid #dc3545;
    background-color: white;
}

.info {
    background-color: #17a2b8;
}
.info_inactive {
    color:  #17a2b8;
    border: 1px solid #17a2b8;
    background-color: white;
}
.debug {
    background-color: #f1f1f1;
    color: black;
}
.debug_inactive {
    color: #919191;
    border: 1px solid #f1f1f1;
    background-color: white;
}
.warning {
    background-color: #ffc107;
}
.warning_inactive {
    color: #ffc107;
    border: 1px solid #ffc107;
    background-color: white;
}

.LevelFilter {
    display: flex;
    margin-top: 1rem;
}
.LevelFilter .Status {
    margin-right: .5rem;
}
.LevelFilter .Status:hover {
    cursor: pointer;
    font-weight: bold;
}

.Title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}