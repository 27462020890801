.JobContainer {
    display: grid;
    gap: .5rem;
    align-items: center;
    max-height: 90vh;
    padding: 1.5rem;
    overflow-y: auto;
}
.Date {
    font-size: small;
    font-weight: bold;
}
.Title {
    grid-column-start: 1;
    grid-column-end: 7;
    display: flex;
    flex-direction: column;
}
.Status {
    grid-column: 8;
    margin: 1rem;
    padding: .25rem;
    color: white;
    font-variant: all-small-caps;
    text-align: center;
    border-radius: 20px;
    font-size: small;
}

.Actions {
    grid-column-start: 9;
    grid-column-end: 11;
}

.Actions button {
    margin-left: .25rem;
}

.error {
    background-color:  #dc3545;
}

.created {
    background-color: #17a2b8;
}
.queued, .started {
    background-color: #007bff;
}
.finished {
    background-color: #28a745;
}

.running {
    background-color: #ffc107;
}
