
.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}
.SideBar {
  flex: 1;
}

.Map {
  flex: 4;
  position: relative;
}
.Panel {
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 999;
  display: flex;
  width: 30vw;
  min-width: 500px;
}
.PanelContent {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  background-color: white;
  overflow: auto;
  border-radius: 10px 0 10px 10px;
  flex:1;
  z-index: 999;
  min-height: 250px;
}
.PanelTabs {
  display: flex;
  flex-direction: column;
}

.PanelTab {
  background-color: #e5e5e5;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 1rem;
  margin-bottom: .25rem;
  position: relative;
}

.PanelTab:hover {
  cursor: pointer;
  background-color: white;

}

.PanelTab.active {
  background-color: white;
  z-index: 999;
}

.PanelCount {
  position: absolute;
  top: -4px;
  right: -9px;
  background-color: #d9534f;
  color: white;
  border-radius: 100%;
  font-weight: bold;
  line-height: 15px;
  font-size: 10px;
  padding: 2px 6px;
}


.Toast {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Login {
  position: absolute;
  width: 30vw;
  height: 220px;
  display: flex;
  flex-direction: column;
  z-index: 9;
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  top: calc(50% - 110px);
  left: calc(50% - 15vw);
}
.Login span {
  margin: 1rem 0;
}

.Login h3 {
  text-align: center;
}

.Login button {
  margin-top: 1rem;
}

.progress {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 15px !important;
}


.rdtPicker {
 position: fixed !important;
}

.Logs {
  position: absolute;
  top: 10%;
  left: 10%;
  height: 80%;
  width: 80%;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  overflow: initial;
  padding: 2rem;
}
