.Panel {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    background-color: white;
    overflow: auto;
    border-radius: 10px;
    z-index: 999;
    position: absolute;
    top: 2rem;
    right: 2rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}
.ResultSelect {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #f0f4ff;
}

.PanelTitle {
    font-weight: bold;
    margin-bottom: 1rem;
}
.Legend {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
}

.LegendHeader {
    display: flex;
    justify-content: space-between;
    margin: .5rem 0;
}
.LegendHeader div {
    max-width: 100px;
}

.LegendHeader span {
    font-size: small;
}

.LegendItem {
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
}

.LegendColor {
    height: 24px;
    width: 24px;
    margin-right: 1rem;
}