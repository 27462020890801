.IntervalContainer {
  display: flex;
}

.DateSelector {
  margin-right: 0.25rem;
  flex: 1;
}

.DateSelector:last-child {
  margin-right: 0;
}
