.Services {
}

.ServiceContainer {
  max-height: 90vh;
  overflow: auto;
  padding: 1.5rem;
}

.ServiceSelect {
  padding: 0.5rem 0;
  display: flex;
}

.ServiceSelect button {
  margin-left: 0.5rem;
}
.ServiceInfo {
  padding-top: 1rem;
  margin-top: 0.5rem;
  border-top: 1px solid #eeeff0;
}

.ServiceTitle {
  font-size: larger;
  font-weight: bold;
}
.Title {
  font-weight: bold;
}

.Description {
  margin-bottom: 0.5rem;
}

.Param {
  padding: 0.5rem;
  border-bottom: 1px solid #eeeff0;
  margin-bottom: 1rem;
}

.Param:last-child {
  border-bottom: none;
}

.ParamInput {
  margin-top: 0.5rem;
}

.Execute {
  margin-top: 1rem;
  display: flex;
  justify-content: stretch;
}

.ExecuteButton {
  flex: 1;
}

.ExecuteButton:not(:last-child) {
  margin-right: 0.5rem;
}

.ProcessGraph {
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
}

.ProcessGraphToggle,
.ClipboardCopy {
  color: var(--secondary-disabled-color);
  display: flex;
  justify-content: flex-end;
}

.ProcessGraphToggle:hover,
.ClipboardCopy:hover {
  cursor: pointer;
}

.ClipboardCopy {
  margin-top: 0.5rem;
  align-items: baseline;
}

th {
  text-align: left;
}
th,
td {
  padding: 0.5rem;
}

.ParamHeader {
  padding: 1rem;
  background-color: whitesmoke;
  border-radius: 10px;
}
.Actions {
  display: flex;
  justify-content: flex-end;
}

.NavBack {
  margin-bottom: 1rem;
  font-size: smaller;
}

.NavBack:hover {
  cursor: pointer;
  font-weight: bold;
}